<template>
  <v-text-field
      ref="inputRef"
      type="text"
      :label="label"
      :value="value"
      :disabled="disabled"
      required
      outlined
      dense
      color="primary"
      class="input-field"
      :rules="rules"></v-text-field>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    options: Object,
    rules: Array,
    label: String,
    value: String,
    disabled: false
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);

    return { inputRef };
  },
};
</script>